
import Navbar from './components/Navbar/Navbar';
import React, { useState } from 'react';
import './App.css';
import TextForm from './components/Navbar/TextForm';
import About from './components/Navbar/About';
import Alert from './components/Navbar/Alert';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';


function App() {

  const [mode, setMode] = useState('light');
  const [alert, setAlert] = useState(null);
  const showAlert = (massege, type) => {
    setAlert({
      massege: massege,
      type: type
    })
    setTimeout(()=>{
      setAlert(null);
    },3000);
  }
  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark');
      document.body.style.backgroundColor = 'grey';
      showAlert("Dark mode has been Enabled", "success")
    }
    else {
      setMode('light');
      document.body.style.backgroundColor = 'white';
      showAlert("Light mode has been Enabled", "success")

    }
  }


  return (
    <div>
      <Router>
        <Navbar title="text" mode={mode} toggleMode={toggleMode} />
        <Alert alert={alert}  />
        <div className="container my-3">
          <Routes>
            <Route exact path="/" element={<TextForm mode={mode} showAlert={showAlert} heading="Enter your text" />}>

            </Route>
            <Route exact path="/about" element={<About />}>

            </Route>
          </Routes>

        </div>
      </Router>
    </div>
  );
}

export default App;
