import React, { useState } from 'react'
import { BrowserRouter } from "react-router-dom";
export default function TextForm(props) {
    const [Text, setText] = useState("Enter Your Text")

    const changetext = (event) => {
        setText(event.target.value);
    }
    const Upcase = () => {
        let newText = Text.toUpperCase();
        setText(newText);
        props.showAlert("Converted", "success")
    }
    const Lpcase = () => {
        let newText = Text.toLowerCase();
        setText(newText);
        props.showAlert("Converted", "success")
    }
    

    return (
        <>
     
        <div className='container ' style={{backgroundColor:props.mode ==='dark'?'grey':'grey'}}>
             <h1>{props.heading}</h1>
             <div className="mb-3">
                <label for="myBox" className="form-label">Exapmle </label>
                <textarea className="form-control" id="myBox" 
                style={{backgroundColor:props.mode ==='dark'?'grey':'light',color:props.mode ==='dark'?'light':'grey'}} value={Text} onChange={changetext} rows="5"></textarea>
             </div>
             <button onClick={Upcase} className="btn btn-primary mx-2">Convert to uppercase</button>

             <button onClick={Lpcase} className="btn btn-primary mx-2">Convert to lowercase</button>
        </div>
        <div className="container my-2" style={{backgroundColor:props.mode ==='dark'?'grey':'grey'}}>
            <h1>Your Text Summary</h1>
            <p>{Text.split(" ").length} words and {Text.length} characters</p>
            <p>{0.008*Text.split(" ").length} minutes required to read these characters</p>
            <h2>Preview:-</h2>
            <p>{Text.length>0?Text:"Enter something to preview"}</p>
        </div>
        </>
    )

}
